import { blue, deepPurple, orange, red, teal, yellow } from "material-ui-colors"
import dashboard from './dashboard.png'
import post from './post.png'
import newpost from './newpost.png'
import group from './group.png'
import profile from './profile.png'

const careerPivotDetails = [
//     {
//     imageUrl: 'https://res.cloudinary.com/deuft4auk/image/upload/v1671405453/Personal%20Website/cray_arrange_s8krcq.png',
//     heading: 'Overview', 
//     text: [

//         'The Tesla Mart V2 CRUD webapp was built using React for the frontend framework and Node for the backend. All RESTful routes are completed using Express with a MongoDB database. This was an individual effort that represents the culmination of two bootcamps and hundreds of hours of research. Building this webapp was a great learning experience and I am very proud of the result.',

//         'Features that I have not yet added include:',

//         'Reset password email',
//         'Multiple selections on a filter'
//         ,
//         'Listing status',
//         'Comment replies',
//         "‘My Listings’ page",
//         "‘Favorites’ page'",

//         'If you have any feedback, please feel free to contact me. I am open to all criticism and look forward to resolving any issues through continued learning.'

//     ]
// },

{
    imageUrl: dashboard,
    background: '#ffe0b2' || orange[100],
    color: '#ef6c00' || orange[800],
    heading: 'Dashboard', 
    text: [

        "The homepage of CareerPivot is the dashboard. This is where all users can view trending and latest posts, whether they are logged in or not.",
        "Users who are logged in and have chosen a career path will see relevant posts. Otherwise, they will see posts from anywhere and everywhere."

    ]
},

{
    imageUrl: post,
    background: '#e3f2fd' || blue[50],
    color: '#1976d2' || blue[700],
    heading: 'Post', 
    text: [

        "The post page shows the post information, a form where users can add a comment, and a list of the comments that can be replied to.",

        ""

    ]
},


{
    imageUrl: newpost,
    background: '#ffebee' || red[50],
    color: '#c62828' || red[800],
    heading: 'New Post', 
    text: [
        "The new post page is where users can post in any of the groups on CareerPivot. The autocomplete dropdown allows users to search for a group to post in and there are text fields for the new post's title and body.",

        "",
    ]
},

{
    imageUrl: group,
    background: '#e0f2f1' || teal[50],
    color: '#00695c' || teal[800],
    heading: "Group", 
    text: [

        "Group pages display a group's information, posts in that group that can be sorted by new, top and hot, and a button that users can press to join or unjoin the group regardless of their career path.",

        "",

        // "When the form is submitted, the images are uploaded to a Cloudinary account, the returned data is added to the request body, and the listing is added to the MongoDB database. Default images are added if no images are uploaded."
    ]
},

{
    imageUrl: profile,
    background: '#fff9c4' || yellow[100],
    color: '#f57f17' || yellow[900],
    heading: "Profile", 
    text: [

        "Users can view and edit their information on the profile page. A new profile picture can be added, a new career path can be selected from the autocomplete field, and the email, username, and password of the user can be modified."
    ]
},

]

export default careerPivotDetails