import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Box, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import InfoCard from './InfoCard';

function SimpleDialog({open, handleClose, details}) {

    const scrollRef = React.useRef(null)

    React.useEffect(()=> {
        scrollRef.current && console.log(scrollRef.current.scrollLeft)
    }, [open])

  return (
    <Dialog open={open} onClose={handleClose} sx={{bgcolor: 'transparent', '& .MuiPaper-root': {backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none', minWidth: '100vw'}, '& .MuiBackdrop-root': {bgcolor: 'rgba(50,50,50,.9)'}}}>
       <IconButton onClick={handleClose} sx={{color: 'background.default', bgcolor: 'background.button', position: 'fixed', top: 10, right: 30, zIndex: 5, marginTop:'12px', '&:hover': {color: 'black', bgcolor: 'primary.main', opacity: .7}, transition: 'all .2s ease-in-out'}}>
           <CloseOutlined />
           </IconButton>
      <Box ref={scrollRef} display='flex' direction='row' overflow='scroll' wrap='nowrap' maxHeight='80vh' width='100%' px={3} columnGap={3} bgcolor='transparent'>
      {details && details.map((t) => (
          <InfoCard key={t.heading} handleClose={handleClose} heading={t.heading} image={t.imageUrl} body={t.text} background={t.background} color={t.color} />
      ))}

      </Box>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function InfoDialog({open, handleClose, details}) {

  return (
      <SimpleDialog
        open={open}
        handleClose={handleClose}
        details={details}
      />
  );
}